import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "hide-scroll", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            {
              staticClass: "fix-bar-jb btm-btn-bg px-0",
              attrs: { cols: "12" },
            },
            [
              _c(
                VRow,
                { staticClass: "px-0 py-0" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0 px-10 jobs-max-width",
                      attrs: { cols: "9" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "py-4 btn-lt-sht text-right" },
                        [
                          _c("HoverTextButton", {
                            staticClass:
                              "f-ns f-nn pl-10 pr-10 ml-6 mt-1 mb-1 btn-wt-bl-text",
                            attrs: {
                              elevation: "0",
                              width: "175",
                              height: "36",
                              textValue: "Back",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.navigateToPreviousRoute($event)
                              },
                            },
                          }),
                          _vm.teamJobDetails
                            ? _c("ButtonActionPrimary", {
                                staticClass: "ml-4",
                                attrs: {
                                  width: "175",
                                  height: 36,
                                  loading: _vm.teamJobsResourcesLoading,
                                  disabled: _vm.teamJobsResourcesLoading,
                                  textValue: "Duplicate",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.duplicateTeamJob()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          (_vm.teamJobDetails.contractorApprovalStatus !=
                            _vm.ApprovalStatusType.Pending ||
                            !_vm.loginOwnerIsContractor) &&
                          !_vm.teamJobDetails.isApprovedForInvoicing &&
                          _vm.hasTeamJobChangesOnDetails &&
                          _vm.teamJobDetails.status !=
                            _vm.TeamJobStatusType.Cancelled &&
                          !_vm.teamJobDisabledAllResources
                            ? _c("ButtonActionPrimary", {
                                staticClass: "ml-4",
                                attrs: {
                                  width: "175",
                                  height: 36,
                                  loading: _vm.teamJobsResourcesLoading,
                                  disabled:
                                    !_vm.hasTeamJobChangesOnDetails ||
                                    _vm.teamJobsResourcesLoading,
                                  textValue: "Discard",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.resetDetailsPage()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          (_vm.teamJobDetails.contractorApprovalStatus !=
                            _vm.ApprovalStatusType.Pending ||
                            !_vm.loginOwnerIsContractor) &&
                          !_vm.teamJobDetails.isApprovedForInvoicing &&
                          _vm.teamJobDetails.status !=
                            _vm.TeamJobStatusType.Cancelled &&
                          !_vm.teamJobDisabledAllResources
                            ? _c("ButtonActionPrimary", {
                                staticClass: "ml-4",
                                class: { disabled: true },
                                attrs: {
                                  width: "175",
                                  height: 36,
                                  loading: _vm.teamJobsResourcesLoading,
                                  disabled:
                                    _vm.teamJobsLoader ||
                                    _vm.isDisabled ||
                                    !_vm.hasTeamJobChangesOnDetails ||
                                    _vm.teamJobsResourcesLoading,
                                  textValue: "Save",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.updateTeamJob()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          _vm.teamJobDetails.status ==
                            _vm.TeamJobStatusType.Pending &&
                          _vm.teamJobDetails.contractor.id == _vm.getSenderId
                            ? _c(
                                "span",
                                [
                                  _c("ButtonActionPrimary", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      width: "175",
                                      height: 36,
                                      loading: _vm.teamJobsResourcesLoading,
                                      disabled: _vm.teamJobsResourcesLoading,
                                      textValue: "Decline",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.openDialogJobDecline()
                                      },
                                    },
                                  }),
                                  _c("ButtonActionPrimary", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      width: "175",
                                      height: 36,
                                      loading: _vm.teamJobsResourcesLoading,
                                      disabled: _vm.teamJobsResourcesLoading,
                                      textValue: "Accept",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.acceptTeamJob()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          _vm.teamJobDetails.status >=
                            _vm.TeamJobStatusType.AwaitingCompletion
                            ? _c(
                                "span",
                                { staticClass: "pa-0" },
                                [
                                  _vm.teamJobDetails.status <
                                    _vm.TeamJobStatusType.PartCompleted &&
                                  _vm.canCompleteJob
                                    ? _c("ButtonActionPrimary", {
                                        staticClass: "ml-4",
                                        attrs: {
                                          width: "175",
                                          height: 36,
                                          loading: _vm.teamJobsResourcesLoading,
                                          disabled:
                                            _vm.teamJobsResourcesLoading,
                                          textValue: "Part complete",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.updateInProgressJob(
                                              "partCompleteTeamJob"
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.teamJobDetails.status <
                                    _vm.TeamJobStatusType.Completed &&
                                  _vm.canCompleteJob
                                    ? _c("ButtonActionPrimary", {
                                        staticClass: "ml-4",
                                        attrs: {
                                          width: "175",
                                          height: 36,
                                          loading: _vm.teamJobsResourcesLoading,
                                          disabled:
                                            _vm.teamJobsResourcesLoading,
                                          textValue: "Complete",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.updateInProgressJob(
                                              "completeTeamJob"
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          _vm.teamJobDetails.contractor &&
                          _vm.teamJobDetails.contractor.id == _vm.getSenderId &&
                          _vm.isVisibleForceFinish()
                            ? _c("ButtonActionPrimary", {
                                staticClass: "ml-4",
                                class: { disabled: true },
                                attrs: {
                                  width: "175",
                                  height: 36,
                                  loading: _vm.teamJobsResourcesLoading,
                                  disabled: _vm.teamJobsResourcesLoading,
                                  textValue: "Force finish",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openDialogToForceFinishTeamJob()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          (_vm.teamJobDetails.isApprovedForInvoicing ||
                            _vm.teamJobDetails.contractor.id ===
                              _vm.teamJobDetails.customer.id ||
                            _vm.teamJobDetails.customer.isInternal) &&
                          _vm.teamJobDetails.status ==
                            _vm.TeamJobStatusType.Completed
                            ? _c("ButtonActionPrimary", {
                                staticClass: "ml-4",
                                attrs: {
                                  "min-width": "175",
                                  height: 36,
                                  textValue: "Show report",
                                  loading:
                                    _vm.invoiceReportGenerationInProgress ||
                                    _vm.teamJobsResourcesLoading,
                                  disabled:
                                    _vm.invoiceReportGenerationInProgress ||
                                    _vm.teamJobsResourcesLoading,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.generateReport()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          _vm.teamJobDetails.status ==
                            _vm.TeamJobStatusType.Completed &&
                          !_vm.teamJobDetails.isApprovedForInvoicing &&
                          !_vm.teamJobDetails.isInvoiced &&
                          _vm.teamJobDetails.customer.id != _vm.getSenderId &&
                          (_vm.loginUserRole == _vm.UserRoleType.Contractor ||
                            (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                              _vm.UserRoleType[_vm.ownerRole] ==
                                _vm.UserRoleType.Contractor))
                            ? _c("ButtonActionPrimary", {
                                staticClass: "ml-4",
                                attrs: {
                                  "min-width": "175",
                                  height: 36,
                                  textValue: "Approve for invoicing",
                                  loading: _vm.teamJobsResourcesLoading,
                                  disabled: _vm.teamJobsResourcesLoading,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.approveJobForInvoicing()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.teamJobDetails &&
                          (_vm.teamJobDetails.isInvoiced ||
                            _vm.teamJobDetails.contractor.id ===
                              _vm.teamJobDetails.customer.id ||
                            _vm.teamJobDetails.customer.isInternal) &&
                          _vm.teamJobDetails.status ===
                            _vm.TeamJobStatusType.Completed &&
                          _vm.teamJobDetails.isInvoiced &&
                          _vm.loginUserRole == _vm.UserRoleType.Contractor
                            ? _c("ButtonActionPrimary", {
                                staticClass: "ml-4",
                                attrs: {
                                  "min-width": "175",
                                  height: 36,
                                  textValue: "View invoice",
                                  loading: _vm.teamJobsResourcesLoading,
                                  disabled: _vm.teamJobsResourcesLoading,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleViewInvoice($event)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(VCol, { attrs: { cols: "3" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.dialogDeclineTeamJob,
            callback: function ($$v) {
              _vm.dialogDeclineTeamJob = $$v
            },
            expression: "dialogDeclineTeamJob",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag py-5", attrs: { height: "280" } },
            [
              _c(VCardTitle, { staticClass: "headline pt-0" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$stringConstants("teamJobDeclinePromtTitle"))
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "pl-1",
                    staticStyle: { "font-size": "12px", opacity: "0.5" },
                  },
                  [
                    _vm._v(
                      " (" +
                        _vm._s(
                          _vm.$stringConstants("teamJobDeclineReasonText")
                        ) +
                        ") "
                    ),
                  ]
                ),
              ]),
              _c(VCardText, { staticClass: "pb-0" }, [
                _c(
                  "div",
                  [
                    _c(VTextarea, {
                      attrs: {
                        rows: "4",
                        "row-height": "30",
                        outlined: "",
                        dense: "",
                        required: "",
                      },
                      model: {
                        value: _vm.declineTeamJobReason,
                        callback: function ($$v) {
                          _vm.declineTeamJobReason = $$v
                        },
                        expression: "declineTeamJobReason",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c("SecondaryButton", {
                    attrs: {
                      variant: "secondary",
                      text: "",
                      color: "primary",
                      outlined: "",
                      outlineColor: "primary",
                      textValue: _vm.$stringConstants("deletePromtDisagree"),
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeJobDecline($event)
                      },
                    },
                  }),
                  _c("SecondaryButton", {
                    staticClass: "ml-3",
                    attrs: {
                      variant: "secondary",
                      color: "primary",
                      textValue: _vm.$stringConstants("deletePromtAgree"),
                      disabled: _vm.declineTeamJobReason.trim().length == 0,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.proceedToDeclineTeamJob($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogForceFinish },
        on: {
          cancel: function ($event) {
            _vm.dialogForceFinish = false
          },
          proceed: _vm.proceedToForceFinishTeamJob,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("teamJobForceFinishPromtTitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("teamJobForceFinishPromtSubtitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }