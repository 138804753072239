import ConstantValues from '@/plugins/constantValues';
//page titles
export var signUpComponentTitle = 'SignUp';
export var resendEmailVerificationComponentTitle = 'Resend Email';
export var jobsTitle = 'Jobs';
export var jobsAddTitle = 'Add job';
export var fieldsTitle = 'Fields';
export var operationsTitle = 'Operations';
export var timesheetsTitle = 'Timesheets';
export var teamMembersTitle = 'Team members';
export var contractorsTitle = 'Contractors';
export var subContractorsTitle = 'Sub-contractors';
export var vehiclesTitle = 'Vehicles';
export var customersTitle = 'Customers';
export var implementsTitle = 'Implements';
export var productsTitle = 'Products';
export var jobsDetailsTitle = 'Job details';
export var integrationsTitle = 'Integrations';
export var invoicesTitle = 'Invoices';
export var invoicesAddTitle = 'Add invoice';
export var invoicesCreateTitle = 'Create invoice';
export var invoicesDetailsTitle = 'Invoice';
export var subscriptionsTitle = 'Subscription';
export var addContactTitle = 'Create customer';
export var customerDetailsTitle = 'Customer details';
export var customerDetailsUpdateTitle = 'Edit customer';
export var overviewTitle = 'Overview';
export var usersTitle = 'Users';
export var businessesTitle = 'Businesses';
export var businessesDetailsTitle = 'Business details';
//component names
export var homeComponentTitle = 'Home';
export var loginComponentTitle = 'Login';
export var jobsComponentTitle = 'Jobs';
export var fieldsComponentTitle = 'Fields';
export var fieldsAddComponentTitle = 'Add Fields';
export var fieldsEditComponentTitle = 'Edit Fields';
export var fieldsRecalculateArea = 'Reset';
export var fieldsRecalculateAreaTooltip = 'Click to reset field area';
export var operationsComponentTitle = 'Operations';
export var timesheetsComponentTitle = 'Timesheets';
export var teamMembersComponentTitle = 'TeamMembers';
export var contractorsComponentTitle = 'Contractors';
export var vehiclesComponentTitle = 'Vehicles';
export var farmersComponentTitle = 'Farmers';
export var implementsComponentTitle = 'Implements';
export var productsComponentTitle = 'Products';
export var forgetPasswordComponentTitle = 'Forget Password';
export var resetPasswordComponentTitle = 'Reset Password';
export var teamJobDetailsComponentTitle = 'Team Job Details';
//products
export var productCreated = 'New product added successfully!';
export var productNotCreated = 'Failed to add new product!';
export var productUpdated = 'Product updated successfully!';
export var productDeleted = 'Product deleted successfully!';
export var productNotDeleted = 'Failed to delete product!';
export var productZeroStateInactive = 'You do not have any inactive products.';
export var productZeroStateHeading = 'You do not have any products.';
export var productZeroState = 'Click on the "Add" button to start creating new products.';
export var productListDisabled = "You're not allowed to view the product list.";
export var productDeletePromptTitle = 'Delete product?';
export var productDeletePromptSubtitle = 'Are you sure you want to delete this product?';
export var productArchivePromptTitle = 'Archive product?';
export var productArchivePromptSubtitle = 'Are you sure you want to archive this product?';
export var productArchiveSuccessMessage = 'Product archived successfully.';
export var productRestorePromptTitle = 'Restore product?';
export var productRestorePromptSubtitle = 'Are you sure you want to restore this product?';
export var productRestoredSuccessMessage = 'Product restored successfully.';
//su-users
export var suUsersZeroStateHeading = 'No users found!';
export var suBusinessesZeroStateHeading = 'No businesses found!';
export var suUsersLoaderDialogTitle = 'Getting users list ready to export';
export var suUsersLoaderDialogContent = 'Please wait, while the users list is being loaded:';
export var suBusinessesLoaderDialogTitle = 'Getting businesses list ready to export';
export var suBusinssesLoaderDialogContent = 'Please wait, while the businesses list is being loaded:';
//products: add
export var productAddTitle = 'Add product';
export var productAddSubtitle = 'Product information';
export var productAddDescription = 'Add details of the product.';
//products: edit
export var productEditTitle = 'Edit product';
export var productEditSubtitle = 'Product information';
export var productEditDescription = 'Update details of the product.';
//fields 
export var fieldZeroState = 'Click on the "Add" button to start creating new fields.';
export var fieldZeroStateHeading = "You don't have any fields";
export var fieldCreated = 'New field added successfully!';
export var fieldNotCreated = 'Failed to add new field!';
export var fieldNotUpdated = 'Failed to update the field!';
export var fieldUpdated = 'Field updated successfully!';
export var deleteFieldWaring = 'Are you sure you want to delete this field?';
export var fieldNotDeleted = 'Failed to delete field!';
export var fieldDeleted = 'Field deleted successfully';
export var fieldDeletePromtTitle = 'Delete field?';
export var fieldListDisabled = "You're not allowed to view the field list.";
export var fieldsBulkUploadSuccess = 'Fields added successfully!';
export var fieldsUploadFailed = 'Failed to upload';
export var fieldsUploadDisclaimerShapeFile = 'Shape files of World Geodetic System 1984 (WGS1984, EPSG:4326) and British National Grid (Ellipsoid : Airy1830, OGSB36) projections are supported. Shape files that do not contain a projection (.prj file) will be considered as WGS1984.';
export var fieldsUploadDisclaimerKML = 'KML files of World Geodetic System 1984 (WGS1984, EPSG:4326) are supported.';
export var fieldAreaCalculationWarning = 'Please do not interrupt this process by refreshing or closing the tab.';
export var fieldAreaValidationSuccess = 'All fields updated!';
export var fieldsUploadFootnote = 'Unsupported files will be ignored.';
export var fieldsListCheckboxMultiSelectAlert = 'Please filter by a customer to enable multiple select';
export var fieldArchivePromptTitle = 'Archive field?';
export var fieldArchivePromptSubtitle = 'Are you sure you want to archive this field';
export var fieldArchiveSuccessMessage = 'Field archived successfully';
export var fieldRestorePromptTitle = 'Restore field?';
export var fieldRestorePromptSubtitle = 'Are you sure you want to restore this field';
export var fieldRestoreSuccessMessage = 'Field restored successfully';
export var fieldZeroStateInactive = 'You do not have any inactive fields.';
export var fieldAddNewText = 'Add New Field';
export var fieldAreaText = 'Field area';
export var fieldDrawingOnMapText = 'Click on the map to start drawing';
export var fieldAreaCannotZeroText = "Area can't be 0";
export var fieldNameText = 'Field name';
export var fieldGetAreaBYDrawText = 'Draw on the area on the map to get the area';
export var fieldEditMapText = 'Edit map';
export var fieldClearMapText = 'Clear map';
export var fieldCropHistoryText = 'Crop history';
export var fieldAddCropHistoryText = 'Add crop history';
export var fieldCropNameText = 'Enter crop name';
export var fieldSelectYearText = 'Select year';
export var fieldManageCropHistoryText = 'Manage crop history for this field.';
export var fieldNoCropAddedText = 'No crops added.';
export var fieldManageJobForFieldText = 'Manage jobs for this field.';
export var fieldNoJobFoundText = 'No jobs found.';
export var fieldUploadText = 'Upload fields';
export var fieldInfoWindowMessage = 'You can edit this shape by dragging the points';
export var fieldAddSelectCustomerAlertMessage = 'Select owner to draw field.';
//time sheets
export var timeSheetUpdated = 'Timesheet updated successfully!';
export var timeSheetCreated = 'New timesheet added successfully!';
export var timeSheetDeletePromtTitle = 'Delete timesheet?';
export var timeSheetDeletePromtSubtitle = 'Are you sure you want to delete this timesheet?';
export var timeSheetNotDeleted = 'Failed to delete the timesheet!';
export var timeSheetDeleted = 'Timesheet deleted successfully!';
export var timeSheetZeroStateHeading = 'No timesheets';
export var timeSheetZeroState = 'You do not have any timesheets. Click on the "Add" button to start creating new timesheets.';
export var timeSheetExportTitle = 'Getting timesheets ready to export';
export var timeSheetExportMessage = 'Please wait, while the timesheets are being loaded:';
export var timeSheetAddText = 'Add timesheet';
//farmers 
export var farmersListZeroStateTitle = "You don't have any customers";
export var farmersListZeroStateSubtitle = 'Click on the "Add" button to start creating new customers.';
export var farmersDeletePromtTitle = 'Delete customer?';
export var farmersDeletePromtSubtitle = 'Are you sure you want to delete this customer?';
export var farmersArchivePromtTitle = 'Archive customer?';
export var farmersRestorePromtTitle = 'Restore customer?';
export var farmersArchivePromtSubtitle = 'Are you sure you want to archive this customer?';
export var farmersRestorePromtSubtitle = 'Are you sure you want to restore this customer?';
export var farmerDeleted = 'Customer removed successfully!';
export var farmerNotDeleted = 'Failed to delete customer';
export var farmerUpdated = 'Customer details updated successfully!';
export var farmersUpdatedFromInvoicingService = 'Customers updated successfully!';
export var farmersImportedFromInvoicingService = 'Customers imported successfully!';
export var farmersUpdateCustomerPromptTitle = 'Update customers?';
export var farmersUpdateCustomerPromptText1 = 'All the profile data from ';
export var farmersUpdateCustomerPromptText2 = ' will override profile information present within ag-drive.';
export var farmersUpdateCustomerPromptText3 = 'Are you sure to update information from ';
export var farmersUpdateCustomerPromptText4 = ' for all the selected customers?';
export var farmerConnectionRequestText = 'Connection request';
export var farmerCompleteInfoText = 'Complete missing info';
export var farmerNotFoundText = 'No customer found';
export var farmerInviteText = 'Invite customer';
export var farmerImportQuickbooksText = 'Import from Quickbooks';
export var farmerImportXeroText = 'Import from Xero';
export var farmerImportSageText = 'Import from Sage';
export var farmerImportIntegrationsText = 'Import from Integrations';
export var farmerImportFileText = 'Import from file';
export var farmerAddCustomerText = 'Add Customer';
export var farmerIntegrationsTitle = 'Integrations';
export var farmerIntegrationsSubTitle = 'Integrate invoices service like Quickbooks, Xero, Sage etc. and directly import your existing customers.';
export var farmerGoToIntegration = 'Go to integrations.';
export var farmerManageCustomer = 'Manage customers';
export var contractorsListZeroStateTitle = "You don't have any contractors!";
export var contractorsListZeroStateSubtitle = 'Click on the "Add" button to start adding';
export var contractorsDeletePromtTitle = 'Delete contractor?';
export var contractorsDeletePromtSubtitle = 'Are you sure you want to delete this contractor?';
export var contractorDeleted = 'Contractor removed successfully!';
export var contractorNotDeleted = 'Failed to remove the contractor!';
export var contractorNotUpdated = "Failed to update contractor's details!";
export var contractorUpdated = "Contractor's details updated successfully!";
export var contractorInviteText = 'Invite new contractor';
export var contractorEditText = 'Edit contractor';
export var contractorManageText = 'Manage contractors';
export var connectionRequestDeleted = 'Connection request deleted';
export var invitationRequestDeleted = 'Invitation request deleted';
export var connectionRequestFailedToDelete = 'Failed to delete connection request';
export var connectionRequestSent = 'Connection request sent';
//vehicles
export var vehiclesListZeroStateTitle = "You don't have any vehicles";
export var vehiclesListZeroStateSubtitle = 'Click on the "Add" button to start creating new vehicles.';
export var vehicleDeletePromtTitle = 'Delete vehicle?';
export var vehicleDeletePromtSubtitle = 'Are you sure you want to delete this vehicle?';
export var vehicleArchivePromptTitle = 'Archive Vehicle?';
export var vehicleArchivePromptSubtitle = 'Are you sure you want to archive this vehicle?';
export var vehicleRestorePromptTitle = 'Restore Vehicle?';
export var vehicleRestorePromptSubtitle = 'Are you sure you want to restore this vehicle?';
export var vehicleListDisabled = "You're not allowed to view the vehicles list.";
export var vehicleSafetyChecklistTitle = 'Vehicle checklist';
export var vehicleSafetyChecklistDescription = 'Add a pre-start checklist for the vehicle to ensure health and safety.';
//vehicles: add
export var vehicleAddTitle = 'Add vehicle';
export var vehicleNameLabel = 'Name *';
export var vehicleBrandLabel = 'Brand *';
export var vehicleModelLabel = 'Model *';
export var vehicleRegistrationNumberLabel = 'Registration number *';
export var vehicleWidthLabel = 'Width';
export var vehicleLengthLabel = 'Length';
export var vehicleWeightLabel = 'Weight';
export var vehicleOwnershipLabel = 'Ownership';
export var vehicleAddSuccessMessage = 'Vehicle added successfully';
export var vehicleZeroStateInactive = 'You do not have any inactive vehicle';
//vehicles: edit
export var vehicleUpdateSuccessMessage = 'Vehicle updated successfully';
export var vehicleDeleteSuccessMessage = 'Vehicle deleted successfully';
export var vehicleEditTitle = 'Edit vehicle';
export var vehicleEditSubtitle = 'Vehicle information';
export var vehicleEditDescription = 'Add details of the vehicle.';
//vehicles: archive/restore
export var vehicleArchiveSuccessMessage = 'Vehicle Archived Successfully';
export var vehicleRestoreSuccessMessage = 'Vehicle Restored Successfully';
//team members
export var teamMembersListZeroStateTitle = 'You do not have any team members';
export var teamMembersListZeroStateSubtitle = 'Click on the "Add" button to start creating new team members.';
export var teamMemberArchivePromtTitle = 'Archive team member?';
export var teamMemberArchivePromtSubtitle = "Are you sure you want to archive this team member? Team members with active jobs won't be archived.";
export var teamMemberDeletePromtTitle = 'Archive team member?';
export var teamMemberRestorePromtTitle = 'Restore team member?';
export var teamMemberDeletePromtSubtitle = 'Are you sure you want to archive this team member?';
export var teamMemberRestorePromtSubtitle = 'Are you sure you want to restore this team member?';
export var teamMemberRestoreBilling = 'This will affect your subscription billing.';
export var teamMemberListDisabled = "You're not allowed to view the team members list.";
export var teamMemberUpdateSuccessMessage = 'Team member updated successfully!';
export var teamMemberDeleteSuccessMessage = 'Team member deleted successfully!';
export var teamMemberArchiveSuccessMessage = 'Team member archive successfully!';
export var teamMemberRestoreSuccessMessage = 'Team member restored successfully!';
export var teamMemberNotArchiveText = 'No archived team members.';
export var teamMemberInviteTitle = 'Invite new member';
export var teamMemberUpdateTitle = 'Update team member';
export var teamMemberRoleBillingPriceLabel = '*without showing billing price';
export var teamMemberRoleRecordJobsPriceLabel = "**can't edit, but record jobs";
export var teamMembersRoleChangeSuccessMessage = 'Roles changed successfully!';
export var teamMembersRoleChangeTableLabelFeatures = 'Feature access';
export var teamMembersRoleChangeTableLabelManager = 'Manager';
export var teamMembersRoleChangeTableLabelTeamLead = 'Team Lead';
export var teamMembersRoleChangeTableLabelOperator = 'Operator';
//team members: edit
export var teamMemberRoleLabel = 'Role';
export var teamMemberWorkingStartTimeLabel = 'Start time';
export var teamMemberWorkingEndTimeLabel = 'End time';
//implements
export var implementsListZeroStateTitle = "You don't have any implements";
export var implementsListZeroStateSubtitle = 'Click on the "Add" button to start creating new implement.';
export var implementDeletePromtTitle = 'Delete implement?';
export var implementDeletePromtSubtitle = 'Are you sure you want to delete this implement?';
export var implementListDisabled = "You're not allowed to view the implements list.";
export var implementZeroStateInactive = 'You do not have any inactive implement';
//implements: add
export var implementAddTitle = 'Add implement';
export var implementAddSuccessMessage = 'Implement added successfully!';
export var implementUpdateSuccessMessage = 'Implement updated successfully!';
export var implementDeleteSuccessMessage = 'Implement deleted successfully!';
export var implementArchivePromptTitle = 'Archive Implement?';
export var implementArchivePromptSubtitle = 'Are you sure you want to archive this implement?';
export var implementRestorePromptTitle = 'Restore Implement?';
export var implementRestorePromptSubtitle = 'Are you sure you want to restore this implement?';
export var implementArchiveSuccessMessage = 'Implement archived successfully.';
export var implementRestoreSuccessMessage = 'Implement restored successfully.';
export var implementSafetyChecklistTitle = 'Implement checklist';
export var implementSafetyChecklistDescription = 'Add a pre-start checklist for the implement to ensure health and safety.';
//implements: edit
export var implementEditTitle = 'Edit implement';
export var implementEditSubtitle = 'Implement information';
export var implementEditDescription = 'Add information about the implement.';
//users
export var usersCompanyJobCode = 'Company job code';
export var usersCompanyJobCodeRequired = usersCompanyJobCode + '*';
export var shortTitleWarning = "Only 2 or 3 letters are allowed and this can't be changed later. This will be used uniquely to identify your jobs.";
export var userInvitationDescription = 'You can see all the connection requests sent and recieved.';
export var usersSignUpTitle1 = 'Start your 14-day free trial';
export var userRequiredFullName = 'Name is required.';
export var userRequiredPassword = 'Password is required.';
export var userRequiredConfirmPassword = 'Confirmation password is required.';
export var userRequiredEmailAddress = 'Email address is required.';
export var userRequiredPhoneNumber = 'Phone number is required.';
export var userRequiredCompanyName = 'Company name is required.';
export var userRequiredTermsAndPrivacyCheckbox = 'Please agree to our terms and conditions to proceed';
export var userMinlengthFullName = "Name should be at least ".concat(ConstantValues.minlengthFullName, " characters long.");
export var userMinlengthPassword = "Password should be at least ".concat(ConstantValues.minlengthPassword, " characters long.");
export var userMaxlengthPassword = "Password should not be more than ".concat(ConstantValues.maxlengthPassword, " characters long.");
export var userMinlengthPhoneNumber = "Phone number should be at least ".concat(ConstantValues.minlengthPhoneNumber, " digits long.");
export var userMaxLengthPhoneNumber = "Phone number cannot be more than ".concat(ConstantValues.maxlengthPhoneNumber, " digits long.");
export var userPasswordCustomValidationError = "Password must contain at least one uppercase, one lowercase character and one number.";
export var userSameAsPassword = 'Passwords entered are not the same.';
export var userFormatEmailAddress = 'Invalid email address.';
export var userFormatFullName = 'Name cannot contain numbers or special charaters.';
export var userFormatPhoneNumber = 'Phone number cannot contain letters or special characters.';
export var userSignUpSuccessMessage = 'Signed up successfully!';
export var userOperatorSignUpSuccessMessage = "".concat(userSignUpSuccessMessage, " Please log in using the mobile application.");
export var userResendEmailVerificationMessage = 'Verification email sent successfully';
export var userVerifyEmailMessage = 'Please verify your email before continuing';
export var userAddressAddSuccessMessage = 'Address updated successfully!';
export var userAddressDeletedSuccessMessage = 'Address Deleted successfully!';
export var userAcceptInvitationPromptTitle = 'Accept invitation?';
export var userAcceptInvitationPromptSubtitle = 'Are you sure you want to accept this invitation?';
export var userDeleteInvitationPromptTitle = 'Delete invitation?';
export var userDeleteInvitationPromptSubtitle = 'Are you sure you want to delete this invitation?';
export var userInviteSendSuccessMessage = 'Invitation sent successfully!';
export var userInviteAcceptSuccessMessage = 'Invitation accepted successfully!';
export var userInviteDeclineSuccessMessage = 'Invitation declined successfully!';
export var userInviteDeclineFailSuccessMessage = 'Failed to decline the invitation!';
export var userInviteAcceptFailMessage = 'Failed to accept invitation.';
export var userEmailVerifySuccessMessage = 'Email verified successfully. You can now login.';
export var userLoginWithXeroText = 'Sign up with Xero';
export var userEmailVerifyFailMessage = 'Failed to verify email.';
export var userWelcomeBackText = 'Welcome back! Please login to continue.';
export var userInvitationExpiredMessage = 'The invitation has been expired!';
export var userShortCompanyNameWarning = shortTitleWarning;
export var userCustomerBusinessProfileMissingWarning = 'Business profile of the selected customer is not complete. Please complete it and then proceed.';
export var userEmailAddressDownloadWaitText = 'Please wait while the file is being downloaded.';
export var userEmailAddressDownloadFailText = 'Failed to download email addresses';
export var uppercaseConstraintErrorMessage = 'Password should contain atleast one uppercase letter';
export var lowercaseConstraintErrorMessage = 'Password should contain atleast one lowercase letter';
export var numericConstraintErrorMessage = 'Password should contain atleast one number';
export var homepageMainTitle = 'The all in one app for Farmers and Contractors.';
export var homepageSubtitle = 'Make your business more accountable and traceable. Become more efficient with paperless records of all farm inputs and operations';
export var userProfileAddressUpdateSuccess = 'Address updated successfully';
export var userProfileAddressAddSuccess = 'Address added successfully';
export var userProfileAddressUpdateFailure = 'Failed to update address';
export var userProfileAddressAddFailure = 'Failed to add address';
export var userAccountBusinessDetailsUpdateSuccess = 'Business details update successfully';
export var createPasswordInputLabel = 'Create password *';
export var confirmPasswordInputLabel = 'Confirm Password *';
export var passwordValidationText = 'Password should contain';
export var agreeText = 'I agree to the';
export var serviceTermsText = 'Terms of Service';
export var privacyPolicyText = 'Privacy Policy';
export var alreadySignedUpText = 'Already have an account?';
export var customerAddedSuccessfully = 'Customer added successfully';
export var customerUpdatedSuccessfully = 'Customer updated successfully';
export var customerAddressAddedSuccessfully = 'Customer address update succesfully';
export var customerAddressUpdatedSuccessfully = 'Address updated succesfully';
export var customerAddressDeleted = 'Address Deleted';
export var customerPersonalDetailsSubTitle = 'Manage their personal details';
export var customerSecondaryContactSubTitle = 'Manage there secondary contact information.';
export var customerBusinessInfoSubTitle = 'Manage their company details';
export var customerBusinessAddressSubTitle = 'Manage there business addresses.';
export var customerColorCodeUpdateSuccess = 'Customer color code updated successfully.';
export var userDeletePromtTitle = 'Delete user?';
export var userDeletePromtSubtitle = 'Are you sure you want to delete user ';
export var userDeleteSuccess = 'User deleted successfully!';
//resend-email-verification
export var verifyEmailText = 'Verify your Email';
export var emailCheckText = 'Check your email';
export var confirmAccountText = ' to confirm your account.';
//change/forget/reset password
export var createPasswordTitle = 'Create new Password';
export var forgetPwdTitle = 'Check Your Mail';
export var forgetPwdText1 = 'Click the link sent to';
export var forgetPwdText2 = ' to reset your password.';
export var resetPwdText = 'Enter a new password for ';
//login
export var noAccountText = "Don't have an account?";
export var firstNameField = 'firstName';
export var emailAddressField = 'emailAddress';
export var emailRequiredField = 'email';
export var phoneNumberField = 'phoneNumber';
export var passWordField = 'passWord';
export var confirmPassWordField = 'confirmPassWord';
export var requiredField = 'required';
export var minLengthField = 'minLength';
export var maxLengthField = 'maxLength';
export var uppercaseConstraint = 'uppercaseConstraint';
export var lowercaseConstraint = 'lowercaseConstraint';
export var numericConstraint = 'numericConstraint';
export var sameAsPasswordField = 'sameAsPassword';
//operations 
export var operationsListZeroStateTitle = "You don't have any operations";
export var operationsListZeroStateSubtitle = 'Click on the "Manage operations" button to start creating.';
export var operationDeletePromtTitle = 'Delete sub-operation?';
export var operationDeletePromtSubtitle = 'Are you sure you want to delete this sub-operation?';
export var operationListDisabled = "You're not allowed to view the operations list.";
export var subOperationManageDeletePromtTitle = 'Delete sub-operation?';
export var subOperationManageDeletePromtSubtitle = 'Are you sure you want to delete this sub-operation?';
//operations: manage
export var operationManageTitle = 'Manage operations';
export var operationManageSubtitle = 'Mark active operations';
export var operationManageMainDescription = 'Select the operation(s) to mark it as active.';
export var operationManageAddButtonDescription1 = "You can't find any item which you want?";
export var operationManageAddButtonDescription2 = 'Click on the add button to create a new one.';
export var operationToAddText = 'Operations to be added:';
//operations: add
export var subOperationAddTitle = 'Add sub-operation';
export var subOperationAddSubtitle = 'Sub-operation information';
export var subOperationAddDescription = 'Add details of the sub-operation.';
export var subOperationSafetyChecklistTitle = 'Health safety checklist';
export var subOperationSafetyChecklistDescription = 'Add a pre-start checklist for the sub-operation to ensure health and safety.';
export var subOperationTaxText = 'Taxable';
export var operationTypeAddSuccessMessage = 'Operation types updated successfully!';
export var subOperationDeletedSuccessMessage = 'Sub-operation deleted successfully!';
export var subOperationAddedSuccessMessage = 'Sub-operation added successfully!';
export var subOperationUpdatedSuccessMessage = 'Sub-operation updated successfully!';
export var subOperationAddBtnText = 'Add sub-operation';
export var subOperationChecklistText = 'Operation pre-start checklist is compulsory';
export var subOperationTaxRatesWarning = 'Please set up tax rates in your account to make it a taxable operation';
export var operationsListColumnTitles = {
  columnName: 'Operation',
  columnAction: 'Action'
};
export var subOperationTaxMissingWarningTitle = 'Unable to make operation taxable';
export var subOperationTaxMissingWarningContent = "You don't have any tax rates set up. Set it up now?";
export var subOperationTaxMissingWarningContentForManager = "Tax rates are not set up on your business account. Please contact your Admin to set it up.";
//operations: edit
export var subOperationEditTitle = 'Edit sub-operation';
export var subOperationSaveTitle = 'Save sub-operation';
export var subOperationEditSubtitle = 'Edit sub-operation';
export var subOperationEditDescription = 'Details of the sub-operation.';
//invoices
export var invoiceAddBusinessInfo = 'Business info';
export var invoiceAddJobsSelection = 'Select completed jobs for a customer';
export var invoiceContactNotLinkedWarningText = "You don't have any linked contact for this customer";
export var invoiceContactStatusCheckText = 'Checking contact link status, please wait';
export var invoiceZeroStateTitleText = 'No invoices found!';
export var invoiceZeroStateSubtitleText = 'Click on "Add" button to create a new invoice';
export var invoiceCreateSuccess = 'Invoice created successfully!';
export var invoiceCreateFail = 'Failed to create invoice';
export var invoiceGenerationWarning = 'Please wait while jobs summary report is being generated and added as an attachment.';
export var invoiceDraftUpdatedText = 'Invoice draft updated successfully!';
export var invoiceCreatedAndSent = 'Invoice created and sent successfully!';
export var invoiceCreatedSuccessMessage = 'Invoice created successfully!';
export var invoiceCustomerInvalidEmailWarning = "Please update customer's email address to create invoice.";
export var invoiceApproveNoJobText = "You don't have any approved completed jobs.";
export var invoiceUpdateSuccessfully = 'Invoice updated successfully!';
export var invoiceDeletedSuccessfully = 'Invoice deleted successfully!';
export var invoiceFailedToDelete = 'Failed to delete invoice.';
export var invoiceSentSuccessfully = 'Invoice sent successfully!';
export var invoiceFailedToSend = 'Failed to send invoice';
export var invoiceUnableToDownload = 'Unable to download invoice';
export var invoiceNoTaxRatesWarning = 'Please set up tax on your account!';
export var invoiceTaxTypeNumberText = 'No.';
//invoices: add-business-info
export var invoiceAddBusinessInfoTitle = 'Enter your business details';
//invoices: draft
export var invoiceDraftDueDate = 'Due date & instructions';
export var invoiceDraftDueDateLabel = 'Due Date*';
export var invoiceDraftBilling = 'Billing';
export var invoiceDraftBillingSubtotal = 'Subtotal';
export var invoiceDraftBillingTotalTax = 'Total';
export var invoiceDraftBillingTotalPayable = 'Total payable amount';
export var invoiceDraftAttachments = 'Attachments';
export var invoiceDeleteTitle = 'Delete invoice?';
export var invoiceDeleteSubTitle = 'Are you sure you want to delete this invoice?';
//invoices: jobs-flyout
export var invoiceFlyoutTitle = 'Jobs';
//invoices-external: add
export var invoiceExternalSelectCustomerAndJob = 'Select a customer and then select jobs from the completed jobs.';
export var invoiceExternalGenerateAndAttachJobReports = 'Attach jobs summary';
export var invoiceExternalGeneratingReports = 'Generating reports, please wait';
export var invoiceExternalRegenerateJobReports = 'Re-generate job reports?';
export var invoiceExternalRegenerateJobReportsMessage = 'Are you sure you want to regenerate the job reports? Existing job reports will be replaced.';
export var invoiceExternalNoJobsZeroStateText = 'You don\'t have any approved completed jobs.';
export var invoiceExternalLinkWarningTitle = 'Link contact?';
export var invoiceExternalAlreadyLinkedWarningText1 = 'is already linked to a customer';
export var invoiceExternalAlreadyLinkedWarningText2 = 'Would you like to replace it with';
//invoicse-external: draft
export var invoiceExternalProductSearchTitle = 'Select or search product';
export var invoiceExternalDraftMissingInfo = 'Missing info';
export var invoiceExternalDraftTaxRateNotSet = 'Tax Rate is not set for';
export var invoiceExternalDraftChartAccountNotSet = 'Chart of Account is not set for';
export var invoiceExternalDraftDueDate = 'Due date & instructions';
export var invoiceExternalDraftDueDateLabel = 'Due Date*';
export var invoiceExternalDraftAttachments = 'Attachments';
//invoices-external: contacts
export var invoiceExternalSelectContactLink = 'Select a contact to link';
//invoices-external
export var invoiceExternalQuickbooksTitle = 'Go To QuickBooks';
export var invoiceExternalSageTitle = 'Go To Sage';
export var invoiceExternalXeroTitle = 'Go To Xero';
//integration
export var integrationNotifyStartText = 'To import customers from your integration, go to';
export var integrationNotifyEndText = 'section, and click Import.';
export var integrationConnectionDiscription = 'Connect the accounting software you use, import or link customers and create invoices for one or multiple jobs. You can also update customers while importing as well.';
export var integrationDisconnectWarningTitle = 'Disconnect?';
export var integrationDisconnectWarningDescription1 = 'You will lose all customer invoicing links and will have to re-link them again. Are you sure you want to disconnect';
export var integrationDisconnectWarningDescription2 = 'integration?';
export var integrationDisconnectText = 'Disconnect';
export var integrationQuickBooksText = 'QuickBooks';
export var integrationXeroText = 'Xero';
export var integrationSageText = 'Sage';
export var integrationXeroIntegrating = 'Integrating with Xero, please wait';
// subscriptions
// subscriptions: card
export var subscriptionCardAddTitle = 'Add card';
export var subscriptionCardDeleteTitle = 'Delete card';
export var subscriptionCardDeleteConfirm = 'Are you sure you want to delete the saved card?';
export var subscriptionCardEndingWith = 'Ending with';
export var subscriptionCardEnter = 'Enter card number:';
export var subscriptionCardSave = 'Save';
export var subscriptionCardCancel = 'Cancel';
export var subscriptionCardDefaultSelection = 'Select as default card?';
export var subscriptionCardDefaultSelectionTitle1 = 'Are you sure you want to select your';
export var subscriptionCardDefaultSelectionTitle2 = 'card ending with';
export var subscriptionCardDefaultSelectionTitle3 = 'as your default card?';
export var subscriptionCardDefaultSelectionAgree = 'Yes';
export var subscriptionCardDefaultSelectionDisagree = 'No';
export var subscriptionStartWithoutTrialOverTitle = 'Start subscription?';
export var subscriptionStartWithoutTrialOverDescription1 = "Your free trial will end on ";
export var subscriptionStartWithoutTrialOverDescription2 = " and your card will be charged post trial.";
export var subscriptionStartWithoutTrialOverQuery = "Do you wish to continue?";
// subscriptions: plan
export var subscriptionAvailablePlansTitle = 'Available plans and pricing';
export var subscriptionPlanCancelTitle = 'Cancel subscription?';
export var subscriptionPlanCancelDesc = 'Your active subscription will be cancelled at the end of the billing cycle.';
export var subscriptionPlanFetchInProgress = {
  title: 'Subscription successful!',
  message: 'Please wait a moment while the plan details are being fetched.'
};
export var subscriptionPlanCancelDisagree = 'No';
export var subscriptionPlanCancelAgree = 'Yes';
export var subscriptionPlanDetailsTitle = 'Your plan';
export var subscriptionPlanFreeTrial1 = 'day free trial';
export var subscriptionPlanFreeTrial2 = 'days remaining';
export var subscriptionPlanTeamSize = 'Team size';
export var subscriptionPlanMembers = 'members';
export var subscriptionPlanActivatedTitle = 'Monthly subscription activated';
export var subscriptionPlanDefaultTeamSize = 'members (small)';
export var subscriptionPlanPaymentCardTitle = 'Payment card';
export var subscriptionPlanCancelBtn = 'Cancel subscription';
export var subscriptionPlanPendingAmount = 'Pending amount';
export var subscriptionPlanPayTitle = 'Pay on stripe';
export var subscriptionPlanDownloadInvoice = 'Download latest invoice / receipt';
export var subscriptionPlanDownload = 'Download';
export var subscriptionPlanNoCardWarning = 'Please enter your card details to start your 14 days free trial';
export var subscriptionPlanNoSubscriptionWarning = 'Click on Start trial to get started';
export var subscriptionPlanStartTrialFootnote = 'Your card will be charged after exhaustion of your free usage quota. Cancellation requests will be processed at the end of current billing cycle or free quota.';
export var subscriptionPlanActivateBtn = 'Start trial';
export var subscriptionPlanPromocodeLabel = 'Redeem promotional code';
export var subscriptionPlanPromocodeInputLabel = 'Enter promo code here';
export var subscriptionPromoCodeActivateBtn = 'Apply';
export var subscriptionPromoCodeActivateSuccessText = 'Promo code applied successfully!';
export var subscriptionCurrentPlanTitle = 'Current subscription plan';
//subscriptions: miscellaneous
export var subscriptionCanclePromptText1 = 'You will loose access to Ag-drive account once you cancel your subscription.';
export var subscriptionCanclePromptText2 = 'Subscription can be renewed anytime.';
export var subscriptionCancelReasonLabel = 'Tell us why you are cancelling';
export var subscriptionTrialText = "You're currently on 14-day free trial plan. After it ends, monthly billing charges will be applied.";
export var subscriptionTrialExpiredText = 'Your 14-day free trial has expired.';
export var subscriptionDetailsText1 = 'Your plan automatically updates when your team size increases. Admin user charges ';
export var subscriptionDetailsText2 = '/month.';
export var subscriptionPlanCancelDateText = ' Your current subsciption plan is scheduled to be cancelled on ';
export var subscriptionCardAddedText = 'You will be billed every month using the added card.';
export var subscriptionCardEndingText = 'Card ending with';
export var subscriptionInvoiceHistoryHeading = 'Invoice History';
export var subscriptionInvoiceHistorySubHeading = 'Check all your past billing invoices.';
export var subscriptionCancelSuccessMessage = 'Susbscription cancelled successfully. Please wait a moment.';
export var subsciptionWithdrawlMessage = 'Susbscription cancellation withdrawn. Please wait a moment.';
export var archiveOrDeleteText = 'Archive / Delete';
export var roleChangeText = 'Change role';
// onboard
export var onboardUserMissingInfoWarning = 'Please update your account details to continue';
//onoard: business-profile
export var onboardBusinessTitle1 = 'Hi ';
export var onboardBusinessTitle2 = ', tell us about your business';
export var onboardBusinessSubtitle = 'This is an initial information of your business. You can change it later.';
//onboard: preferences
export var onboardPreferenceTitle = 'Setup default business preferences';
export var onboardPreferenceSubtitle = 'You can change these settings later.';
export var onboardPreferenceWorkStart = 'Start time *';
export var onboardPreferenceWorkEnd = 'End time *';
export var onboardPreferenceFieldArea = 'Field measurement unit *';
export var onboardPreferenceFieldColor = 'Default Field Color';
export var onboardInvoicingTitle = 'Setup invoicing and taxes';
export var onboardInvoicingSubTitle = 'You can change these settings later.';
//onboard
export var onboardUserPayInfoText = 'User payment info filled.';
export var onboardInvalidCoordinate = 'Invalid coordinates entered!';
export var onboardDefaultInvoiceTitle = 'Default invoice settings';
// zero invitations
export var emptyInvitaionList = 'No pending invitations available!';
// search errors
export var errorForWrongSearch = 'No data available for this request!';
//reset password
export var forgotPassword = 'Forgot password?';
export var resetPassword = 'Reset password';
export var resetPasswordInformation = "Enter the email associated with your account and we'll send you a link to reset your password.";
export var requestSendToEmail = 'A reset password link has been sent to your registered email address. Please check!';
export var passwordResetSuccess = 'Password Reset Successfully';
//search enter
export var enterToSearch = 'Press enter to search';
//search placeholder
export var searchCustomerPlaceholder = 'Search by name, email or phone number';
export var searchFieldPlaceholder = 'Search field';
export var accountMapSearchPlaceholder = 'Type location or enter cordinates (Lat, Long)';
export var fieldUnitPlaceholder = 'Select field area unit';
export var addressLineOnePlaceholder = 'e.g. Town house Agricultural';
export var addressLineTwoPlaceholder = 'e.g. Street number 4';
export var cityPlaceholder = 'e.g. Brentwood';
export var countryPlaceholder = 'e.g. United Kingdom';
export var countyPlaceholder = 'e.g. East Lothian';
export var postalCodePlaceholder = 'e.g. 12KL523';
export var businessTitlePlaceholder = 'e.g. Agricultural pvt ltd';
export var businessPrefixPlaceholder = 'e.g. ABC';
export var vatNumberPlaceholder = 'e.g. GHS43434HDS';
export var registrationNumberPlaceholder = 'e.g. GHS43434HDS';
export var namePlaceholder = 'e.g. Alex';
export var emailPlaceholder = 'e.g. alex@example.com';
export var phoneNumberPlaceholder = 'e.g. 07856633431';
export var contractorSearchPlaceholder = 'Search contractor by name';
export var statusPlaceholder = 'Status';
export var companyNamePlaceholder = 'eg. Zdix Farms Co Ltd.';
export var searchCustomerTextPlaceholder = 'Search customers';
export var fieldNamePlaceholder = 'e.g. Pasture field';
export var selectOwnerPlaceholder = 'Select owner';
export var selectOwnershipPlaceholder = 'Select ownership';
export var cropPlaceholder = 'e.g. Paddy';
export var yearPlaceholder = 'Select year';
export var farmPlaceholder = 'Select farm';
export var notePlaceholder = 'Write something';
export var areaPlaceholder = 'Area';
export var selectCustomerPlaceholder = 'Select customer';
export var selectFieldPlaceholder = 'Select field';
export var selectShapeFilePlaceholder = 'Select shape files (.zip)';
export var unitPlaceholder = 'Select unit';
export var enterPasswordPlaceholder = 'Enter password';
export var confirmPasswordPlaceholder = 'Confirm Password';
export var machineTitlePlaceholder = 'eg. Tractor';
export var machineMakePlaceholder = 'eg. kawasaki';
export var machineModelPlaceholder = 'eg. 2021';
export var machineRegistrationPlaceholder = 'eg. LX54 5345';
export var selectVehiclePlaceholder = 'Search vehicles';
export var selectImplementPlaceholder = 'Search Implements';
export var selectProductPlaceholder = 'Search products';
export var searchOperationPlaceholder = 'Search operations';
export var subOperationPlaceholder = 'e.g. Bailing round';
export var productTitlePlaceholder = 'eg. Fuel';
export var productSearchPlaceholder = 'Search product by name';
export var usersSearchPlaceholder = 'Search name, business name, email';
export var businessesSearchPlaceholder = 'Search name, business name, email';
export var createPasswordPlaceholder = 'Create a strong password';
export var enterPasswordAgainPlaceholder = 'Enter the password again';
export var selectOperatorPlaceholder = 'Select operator';
export var selectOperationPlaceholder = 'Select operation';
export var searchTextPlaceholder = 'Search';
export var enterAmountPlaceholder = 'Enter amount';
export var selectFarmsAddressPlaceholder = 'Select farm address';
export var teamMemberSearchPlaceholder = 'Search team member by name';
export var teamMemberSelectPlaceholder = 'Select team member';
export var zeroQuantityPlaceholder = '0';
//checklists
export var checkListItemLabel = 'Checklist item';
export var checkListItemEnterPromtLabel = 'Enter checklist item';
export var healthSafetyChecklistMandatoryText = 'Health & safety checklist is compulsory';
export var vehicleSafetyChecklistMandatoryText = 'Vehicle pre-start checklist is compulsory';
export var implementSafetyChecklistMandatoryText = 'Implement pre-start checklist is compulsory';
//flyout
export var addBtnText = 'Add';
export var updateBtnText = 'Save changes';
//others
export var deletePromtAgree = 'Yes';
export var deletePromtDisagree = 'No';
export var addItemBtnText = 'Add';
export var listLoadMoreText = 'See more';
export var downloadText = 'Download';
export var refreshText = 'Refresh';
export var addNewText = 'Add';
export var addNewContractor = 'Add contractor';
export var addNewField = 'Add field';
export var updateField = 'Save';
export var archivePromptAgree = 'Yes';
export var archivePromptDisagree = 'No';
export var restorePromptAgree = 'Yes';
export var restorePromptDisagree = 'No';
export var jobPrefixText = shortTitleWarning;
export var personalDetailsTitle1 = 'Personal Details';
export var personalDetailsTitle2 = 'Manage your personal details';
export var makeContractorText = 'Make contractor';
//account
export var accountTitle = 'Account';
//super-admin
export var superAdminAccessCodePrompt = 'Please enter the access code received on your email';
export var cancellationScheduledOnText = 'Cancellation scheduled on ';
export var cancelledOnText = 'Cancelled on ';
export var businessesDeleteTitle = 'Delete business?';
export var businessesDeleteSubtitle = 'Are you sure you want to delete this business?';
export var businessesRoleChangeTitle = 'Change role to contractor?';
export var businessesRoleChangeSubtitle = 'Are you sure you want to change role to contractor?';
export var businessDeleteSuccess = 'Business deleted successfully!';
export var businessRoleChangeSuccess = 'Role changed to contractor successfully!';
//account:profile
export var accountDetailsNameLabel = 'Name';
export var accountDetailsEmailLabel = 'Email Address';
export var accountDetailsPhoneLabel = 'Phone Number';
export var accountDetailsSecondaryNameLabel = 'Secondary Name';
export var accountDetailsSecondaryContactLabel = 'Secondary Contact';
export var accountDetailsContactInfoHeading1 = 'Contact Information';
export var accountDetailsContactInfoHeading2 = "Mange your company's phone number and address.";
export var accountDetailsPersonalPreferencesHeading1 = 'Personal Preferences';
export var accountDetailsPersonalPreferencesHeading2 = 'These preferences will be applied to your account.';
//account:business
export var accountCompanyDetailsHeading1 = 'Company Details';
export var accountCompanyDetailsHeading2 = 'Manage your company details';
export var accountCompanyAddressesHeading2 = 'Manage your company address';
export var accountCompanyAddressesHeading1 = 'Company address';
export var accountCompanyGeneralAndDefaultHeading1 = 'General default settings';
export var accountCompanyLocationHeading2 = 'This location will be set as default to help you map your fields.';
export var accountCompanyPaymentHeading1 = 'Invoice and General Settings';
export var accountCompanyPaymentHeading2 = 'Manage your invoicing and taxes';
export var accountCompanyTeamHeading1 = 'Team Settings';
export var accountCompanyTeamHeading2 = 'These settings will be applied to your team.';
export var accountGeneralSettingsHeading = 'General Settings';
export var accountGeneralSettingsSubHeading = 'Manage your default currency';
//account:Farms
export var accountCompanyFarmsHeading1 = 'Your Farms';
export var accountCompanyFarmsHeading2 = 'Add your farms here. You can map your fields for each farm in the fields section.';
export var accountInvalidCoordinates = 'Invalid coordinates entered!';
export var createNewTaxText = 'Press enter to create';
export var taxUsedOnText = 'This will be used only for in-built invoicing.';
//fields Label String/Constant.
export var fieldLabelName = 'Name *';
export var fieldLabelBillingCost = 'Billing Rate *';
export var fieldLabelUnit = 'Unit *';
export var fieldLabelVehicles = 'Vehicles';
export var fieldLabelImplements = 'Implements';
export var fieldLabelProducts = 'Products';
export var fieldLabelDescription = 'Description';
export var buttonNameSaveChanges = 'Save changes';
//customers: strings
export var secondaryContactInfoHeading1 = 'Secondary Contact';
export var secondaryContactInfoHeading2 = 'Manage your secondary contact information.';
export var companyDetailsHeading1 = 'Business Info';
export var companyDetailsHeading2 = 'Manage company details';
export var companyAddressesHeading1 = 'Business Addresses';
export var companyAddressesHeading2 = 'Manage your business addresses.';
export var farmsHeading1 = 'Farms';
export var farmsExternalContactHeading = 'View all farm addresses.';
export var farmsHeading2 = 'Add customer farms here, you can map customer fields with these farms to manage here.';
export var fieldDetailsHeading1 = 'Fields(';
export var fieldDetailsHeading2 = 'Manage fields details';
export var editContactManatoryFieldsText = 'Mandatory fields are marked with *';
//menu list-item text
export var listItemView = 'View';
export var listItemEdit = 'Edit';
export var listItemDelete = 'Delete';
//jobs
export var teamJobZeroStateDescription = 'You do not have any jobs right now. All your assigned jobs will appear here. You can click on the "Add" button to create a self job.';
export var teamJobZeroStateTitle = 'No jobs';
export var teamJobLoaderDialogTitle = 'Getting jobs ready to export';
export var teamJobLoaderDialogContent = 'Please wait, while the jobs are being loaded:';
export var teamJobDeletePromtTitle = 'Delete job?';
export var teamJobDeletePromtSubtitle = 'Are you sure you want to delete this job?';
export var teamJobForceFinishPromtTitle = 'Are you sure you want to force finish this job?';
export var teamJobForceFinishPromtSubtitle = 'Please note that this will finish or skip work for all the assigned operators.';
export var teamJobCancelPromtTitle = 'Cancel Job?';
export var teamJobDeletePromptTitle = 'Delete job?';
export var teamJobCancelPromtSubtitle = 'Are you sure you want to cancel job?';
export var teamJobDeletePromptSubtitle = 'Are you sure you want to delete this job?';
export var teamJobCancelReasonText = 'Please submit the reason to cancel job';
export var teamJobDeclinePromtTitle = 'Decline job?';
export var teamJobDeclineReasonText = 'Please submit the reason to decline job';
export var teamJobLocationTypeTitle = 'Select location type';
export var teamJobLocationInstructionTitle = 'Instructions for this location';
export var teamJobGeneralInformationTitle = 'General Information';
export var teamJobGeneralInformationSubTitle = 'Fill in mandatory information to create the job';
export var teamJobScheduleDateTitle = 'Scheduled date & time';
export var teamJobLocationTitle = 'Locations';
export var teamJobLocationSubTitle = 'Direct operators with instructions, via google maps, to an address or pin drop';
export var teamJobLocationAlreadyAdded = 'Address already added!';
export var teamJobLocationInvalid = 'Invalid address!';
export var teamJobLocationInvalidCoordinates = 'Invalid coordinates selected!';
export var teamJobLocationAddText = '+ Add location';
export var teamJobLocationDropPinText = 'Dropped pin at';
export var teamJobBillingTitle = 'Billing information';
export var teamJobBillingSubTitle = 'Provide billing quantity for the operation performed on the job';
export var teamJobBillingInformation = 'Billing quantity will be auto-calculated based on changes to work hours, break time or down time';
export var teamJobTimeRecordsTravelTime = 'Travel time will not be auto-calculated for team jobs where multiple operators work together';
export var teamJobTimeRecordsBreakTime = 'Break time will not be auto-calculated for team jobs where multiple operators work together';
export var teamJobNoteAndPhotosTitle = 'Notes & photos';
export var teamJobNoteAndPhotosSubTitle = 'Provide any notes and photos that are relevant to the job';
export var teamJobFieldsTitle = 'Fields';
export var teamJobFieldsSubTitle = 'Select fields from the map or drop-down list';
export var teamJobFieldsLoadingText = 'Please wait, loading fields:';
export var teamJobFieldsTotalArea = 'Total area:';
export var teamJobInstructionsTitle = 'Instructions';
export var teamJobInstructionsSubTitle = 'Provide instructions and photos for the operators on the job';
export var teamJobExtraInformationTitle = 'Extra information';
export var teamJobExtraInformationSubTitle = 'Add any extra information required for the job';
export var teamJobExtraInformationWarning = 'Load already added! Please update the quantity instead.';
export var teamJobAddExtraInformationText = '+ Add extra info.';
export var teamJobOperatorsTitle = 'Operators';
export var teamJobOperatorsSubTitle = 'Select multiple operators and team leads for the job. Leave blank to keep it unassigned';
export var teamJobAddOperatorText = '+ Add operator';
export var teamJobAddAnotherText = '+ Add another';
export var teamJobProductsTitle = 'Products';
export var teamJobProductsSubTitle = 'Select products to be added on the job';
export var teamJobAddProductsText = '+ Add product';
export var teamJobTimeRecordsTitle = 'Time Records';
export var teamJobTimeRecordsSubTitle = 'Time records are automatically added whenever a job is part completed and resumed';
export var teamJobDeclinedSuccessfully = 'Job declined succesfully';
export var teamJobCancelledSuccessfully = 'Job cancelled succesfully';
export var teamJobDetailsUpdatedWarning = 'It seems like this job has been updated by someone else. Job will have to be reloaded.';
export var teamJobOperationNotFoundWarning = 'The selected operation has been deleted!';
export var teamJobTotalBillableTime = 'Total billable time';
export var teamJobZeroProductsText = 'No products were selected.';
export var teamJobZeroMetaDataText = 'No extra information was added.';
export var teamJobZeroFieldText = 'No fields were added.';
export var teamJobZeroAddressText = 'No addresses were added.';
export var teamJobCancelBtnText = 'Cancel job';
export var teamJobDeleteBtnText = 'Delete job';
export var teamJobEnterAddressText = 'Enter new address';
export var teamJobDropPinText = 'Drop pin';
export var jobsReportComponentTitle = 'Job Report';
export var jobReportGenerationMessage = 'Report generated successfully.';
export var jobDuplicationInProgress = 'Please wait a moment, while we duplicate data';
export var teamJobsFilterCustomersPaginationModule = 'teamJobsFilterCustomers';
export var teamJobsFilterTeamMembersPaginationModule = 'teamJobsFilterTeamMembers';
export var teamJobsFilterFieldsPaginationModule = 'teamJobsFilterFields';
export var teamJobsFilterVehiclesPaginationModule = 'teamJobsFilterVehicles';
export var teamJobsFilterImplementsPaginationModule = 'teamJobsFilterImplements';
export var teamJobsFilterProductsPaginationModule = 'teamJobsFilterProducts';
export var fieldsFilterCustomersPaginationModule = 'fieldsFilterCustomers';
export var fieldsMappingFilterCustomersPaginationModule = 'fieldsMappingFilterCustomers';
export var fieldsAddFilterCustomersPaginationModule = 'fieldsAddFilterCustomers';
export var fieldsEditFilterCustomersPaginationModule = 'fieldsEditFilterCustomers';
export var fieldsBulkUploadFilterCustomersPaginationModule = 'fieldsBulkUploadFilterCustomers';
export var subOperationVehiclesDropdownModule = 'vehiclesDropdown';
export var subOperationImplementsDropdownModule = 'implementsDropdown';
export var subOperationProductsDropdownModule = 'productsDropdown';
export var invoicesListFilterCustomersPaginationModule = 'invoicesFilterCustomers';
export var invoicesProductListFilter = 'invoicesProductListFilter';
//excel import
export var uploadNotesDeleteColumnWarning = 'Do not delete any column from the sample file. If you do not wish to provide information in an optional column, leave the data blank.';
export var uploadNotesCommaWarning = 'Avoid using comma in any of the row data. This might corrupt the file.';
//bulk action response title
export var bulkActionResponseTitleCustomersImport = 'Customers import result';
export var bulkActionResponseTitleCustomersUpdate = 'Customers update result';
export var bulkActionResponseTitleFiledsImport = 'Fields import result';
//address
export var addresses = 'Addresses';
export var addressesLine1 = 'Address line 1';
export var addressesLine2 = 'Address line 2';
export var city = 'City';
export var townAndCity = 'Town/City';
export var country = 'Country';
export var stateAndCounty = 'State/County';
export var postalCode = 'Postal code';
//button text
export var cancel = 'Cancel';
export var doneText = 'Done';
export var update = 'Update';
export var back = 'Back';
export var remove = 'Remove';
export var restore = 'Restore';
export var viewDetailsText = 'View details';
export var acceptText = 'Accept';
export var noThanksText = 'No thanks';
export var sendInvitationText = 'Send invitation';
export var manageText = 'Manage';
//input titles
export var title = 'Title';
export var name = 'Name';
export var value = 'Value';
export var unit = 'Unit';
export var usage = 'Usage';
export var loadName = 'Load name';
export var operation = 'Operation';
export var teamLead = 'Team Lead';
export var vehicle = 'Vehicle';
export var implement = 'Implement';
export var product = 'Product';
export var checkList = 'Checklist';
export var timeDuration = 'Time duration';
export var contractor = 'Contractor';
export var customer = 'Customer';
export var startDateAndTime = 'Start date & time';
export var endDateAndTime = 'End date & time';
export var travelTime = 'Travel time';
export var breakTime = 'Break time';
export var downTime = 'Down time';
export var workTime = 'Timings';
export var workHours = 'Work time';
export var totalTime = 'Total time';
export var startTime = 'Start time';
export var endTime = 'End time';
export var latitude = 'Latitude';
export var longitude = 'Longitude';
export var jobNo = 'Job No';
export var jobsText = 'Jobs';
export var scheduledFor = 'Scheduled For';
export var fields = 'Fields';
export var operators = 'Operators';
export var billableQuantity = 'Billable quantity';
export var status = 'Status';
export var emailStatus = 'Email status';
export var invoice = 'Invoice';
export var fieldAreaUnit = 'Fields area default unit';
export var breakTimeMinutes = 'Break time (minutes)';
export var profile = 'Profile';
export var business = 'Business';
export var farms = 'Farms';
export var defaultFieldColor = 'Field default color';
export var companyName = 'Company name';
export var companyAddress = 'Company address';
export var companyJobCode = 'Company job code';
export var vatNumber = 'VAT number';
export var companyRegistrationNumber = 'Company registration number';
export var editFarm = 'Edit Farm';
export var addFarm = 'Add Farm';
export var paymentInstruction = 'Payment instructions';
export var phoneNumber = 'Phone number';
export var secondaryContact = 'Secondary contact';
export var emailAddress = 'Email address';
export var personalAddress = 'Personal address';
export var personalInfo = 'Personal Info';
export var seeOnMap = 'See on map';
export var seeAll = 'See All';
export var businessName = 'Business name';
export var contact = 'Contact';
export var address = 'Address';
export var inviteeName = 'Invitee Name';
export var inviteeEmail = 'Invitee Email';
export var inviteeOn = 'Invited On';
export var expiredOn = 'Expired On';
export var action = 'Action';
export var moreRequest = 'more requests';
export var selectAll = 'Select all';
export var customerName = 'Customer name';
export var emailText = 'Email';
export var phoneText = 'Phone';
export var ownerText = 'Owner';
export var ownershipText = 'Ownership';
export var noteText = 'Note';
export var hideDetailsText = 'Hide details';
export var yearText = 'Year';
export var cropText = 'Crop';
export var areaText = 'Area';
export var jobHistory = 'Job history';
export var farmText = 'Farm';
export var addContact = 'Add contact';
export var invoicingText = 'Invoicing';
export var completedOn = 'Completed on';
export var completedBy = 'Completed by';
export var businessProfileText = 'Business Profile';
export var businessDetailsText = 'General details';
export var previewInvoiceText = 'Preview invoice';
export var totalAmountText = 'Total Amount';
export var taxAmountText = 'Amount';
export var amountText = 'Amount';
export var quantityText = 'Quantity';
export var rateText = 'Rate';
export var particularsText = 'Particulars';
export var numberText = 'Number';
export var itemsText = 'Items';
export var costText = 'Cost';
export var dueDateText = 'Due Date';
export var dateText = 'Date';
export var internalText = 'Internal';
export var invoiceDetailsText = 'Invoice details';
export var invoiceNumberText = 'Invoice number';
export var productAndServicesText = 'Product & Services';
export var descriptionText = 'Description';
export var unitPriceText = 'Unit Price';
export var taxText = 'tax';
export var accountText = 'Account';
export var logInText = 'Log in';
export var passWordText = 'Password';
export var registrationText = 'Registration';
export var brandText = 'Brand';
export var modelText = 'Model';
export var logoutText = 'Logout';
export var loadInformaitionText = 'EXTRA INFORMATION';
export var chargeableUnitText = 'Chargeable unit';
export var pricePerUnitText = 'Price per unit';
export var accountSignUpText = 'account sign up';
export var myCardText = 'My cards';
export var setAsDefaultText = 'Set as default';
export var cardText = 'Card';
export var billText = 'Bill';
export var draftText = 'Draft';
export var openText = 'Open';
export var paidText = 'Paid';
export var applyPromoCodeText = 'Apply promo code';
export var amountDueText = 'Amount due';
export var manageCradText = 'Manage cards';
export var paymentInformationText = 'Payment Information';
export var promoCodeApplyText = 'Promo code applied';
export var totalCostText = 'Total cost';
export var nextPaymentOnText = 'Your card will be charged on';
export var trialingEndsOnText = 'Your card will start charging after ';
export var EndedOnText = 'Ended on';
export var EndsOnText = 'Ends on';
export var monthlyBillingText = 'Monthly billing';
export var freeTrialText = 'Free trial';
export var roleText = 'Role';
export var teamMemberText = 'Team member';
export var timeSheetTypeText = 'Timesheet type';
export var timeDetailsText = 'Time Details';
export var memberNameText = 'Member name';
export var memberText = 'Member';
export var totalTimesheetsText = 'Total timesheets';
export var totalHoursText = 'Total hours';
export var workingHoursText = 'Working hours';
export var breakTimeHoursText = 'Breaktime hours';
export var travelTimeHoursText = 'Travel time hours';
export var holidayText = 'All day (Holiday)';
export var sickText = 'All day (Sick)';
export var showJobsText = 'Show Jobs';
export var invitedByText = 'You have been invited by';
export var invitationExpiresOnText = 'Invitation expires on';
export var invitationAlreadyExpiredText = 'Your invitation has been expired.';
export var invalidInvitationText = 'Invalid invitation';
export var goToJobsText = 'Go to jobs';
export var defaultColorText = 'Default color';
export var deletingText = 'Deleting';
export var permanentlyRemoveText = 'will permanently remove them.';
export var confirmationToDeleteText = 'Are you sure you want to delete?';
export var archiveText = 'Archive';
export var archivingText = 'Archiving';
export var archivingTextTeamMembers = "Team members with active jobs won't be archived.";
export var willRemoveThemText = 'will remove them.';
export var confirmationToArchiveText = 'Are you sure you want to archive?';
export var restoreText = 'Restore';
export var willRestoredText = 'will be restored.';
export var confirmationToRestoreText = 'Are you sure you want to restore?';
export var importText = 'Import';
export var downloadSampleCsvText = 'Download a sample CSV';
export var csvRequiredFormatText = 'file to use it to fill data in the required format.';
export var csvRequiredFieldText = 'The following fields are required:';
export var successfullyImportedText = 'imported successfully.';
export var errorsImportingStartingText = 'There were errors importing';
export var errorsImportingEndingText = 'from your CSV file.';
export var fixErrorAndUploadAgainText = 'file to fix errors, and upload again.';
export var rowNoText = 'Row No.';
export var errorText = 'Error';
export var addOrDropFileText = 'Add or drop file here (CSV format)';
export var disconnectOtherServicesStartingText = 'Disconnect other services to';
export var disconnectOtherServicesEndingText = 'connect with this service.';
export var connectedOnText = 'Connected on';
export var itemsSelectedOnPageText = 'Items selected on this page.';
export var allText = 'All';
export var itemsAreSelectedText = 'Items are selected.';
export var clearAllText = 'Clear all';
export var noSearchResultFoundText = 'No search results found';
export var notArchivedText = 'Not archived';
export var itemDeletedSuccessfully = 'Items deleted successfully';
export var itemArchivedSuccessfully = 'Items archived successfully';
export var someErrorOccuredText = 'Some error occured';
export var failedToArchivedItems = 'Failed to archive items';
export var itemRestoreSuccessfully = 'Items restored successfully';
export var failedToRestoreItems = 'Failed to restore items';
export var noConnectionReceived = 'No connection requests received';
export var noConnectionSent = 'No connection requests sent';
export var connectionRequestTitle = 'Connection requests';
export var okayText = 'Okay';
export var informationText = 'Information';
export var showMiniLoadMoreButton = false;
export var currencyPlaceholderText = "Select currency";
export var timeZonePlaceholderText = "Select time zone";
export var currencyTitle = "Currency";
export var timeZoneTitle = "Time zone";
export var taxesText = "Taxes";
export var taxeTypeText = "Tax name";
export var RegNumText = "number";
export var RegNumTextCaps = "Tax number";
export var taxRateTitle = "TAX RATES";
export var taxDetailsTitle = "TAX Details";
export var rateNameTitle = "Rate name";
export var ratePercentageTitle = "Rate percentage (%)";
export var rateNamePlaceholder = "eg. Standard";
export var ratePercentagePlaceholder = "eg. 3.5";
export var defaultSettingsTitle = "Default settings";
export var defaultLocationTitle = "Default location";
export var teamWorkTimingTitle = "TEAM WORK TIMINGS";
export var percentageSymbol = '%';
export var invoiceTermsHeading = 'Invoice terms (in days)';
export var createNewTaxPlaceholder = 'Select or create new tax';
export var selectDatePlaceholder = 'Select date';
export var declinedText = 'Declined';
export var approveInvoiceText = 'Approved for invoicing';
export var invoiceText = 'Invoiced';
export var approvedText = 'Approved';
export var addNewProductText = 'Add new product';
export var productInListText = 'This product is already added *';
export var editJob = 'Edit job';
export var deleteJob = 'Delete job';
export var cancelJob = 'Cancel job';
export var searchJobs = 'Search jobs';
export var searchJobsLabel = 'Search job by ID, operation or customer';
export var scheduleJobs = 'Schedule';
export var archiveJobs = 'Archive';
export var fileSizeWarning = "File size exceeds 10 MB";
export var scheduleDateAndTime = 'Schedule date & time';
export var addJobMakeTeamLeadLabel = "Make team lead";
export var jobUpdateSuccess = "Job updated successfully";
export var submitUnscheduledJobs = "Save";
export var unscheduledJobsListModule = "unscheduledJobsList";
export var menuViewJob = 'View';
export var menuEditJob = 'Edit';
export var Duplicate = 'Duplicate';
export var menuCancelJob = 'Cancel';