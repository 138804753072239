import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass:
            "d-flex py-3 pl-3 pr-4 justify-space-between calendar-job",
        },
        [
          _c(
            "div",
            {},
            [
              _c(VSkeletonLoader, {
                attrs: { type: "text, text", width: "100", height: "14" },
              }),
              _c(VSkeletonLoader, {
                staticClass: "mt-1",
                attrs: { type: "text, text", width: "50", height: "14" },
              }),
            ],
            1
          ),
          _c(VSkeletonLoader, {
            attrs: { type: "avatar", width: "28", height: "28" },
          }),
        ],
        1
      )
    : _c(
        "div",
        _vm._g(
          {
            class:
              (_vm.draggable ? "draggable" : "") +
              " job-wrapper postion-relative d-flex pl-2 pr-4 py-2 justify-space-between align-center",
            style: { color: _vm.color.dark },
            attrs: {
              id: _vm.id,
              status: _vm.teamJobStatusLabel.toLowerCase(),
              draggable: _vm.draggable,
            },
            on: {
              dragstart: function ($event) {
                $event.stopPropagation()
                return _vm.handleDragStart($event)
              },
              dragover: function ($event) {
                $event.preventDefault()
              },
            },
          },
          _vm.$listeners
        ),
        [
          _c(
            "div",
            {
              class:
                "custom-event d-flex flex-column flex-grow-0 pr-2 width-" +
                (_vm.isSingleDayJob
                  ? _vm.operators.length == 0
                    ? "0"
                    : "1"
                  : _vm.operators.length > 3
                  ? "3"
                  : _vm.operators.length),
            },
            [
              _c(
                VTooltip,
                {
                  attrs: {
                    top: "",
                    "content-class": "black rounded-0 pa-3",
                    transition: "scroll-y-transition",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "span",
                            _vm._g(
                              { staticClass: "event-title color-black py-1" },
                              on
                            ),
                            [_vm._v(" " + _vm._s(_vm.name) + " ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" " + _vm._s(_vm.name) + " ")]
              ),
              _c("span", { staticClass: "event-number color-black" }, [
                _vm._v(
                  _vm._s(
                    _vm.isContractorOrContractorManagerLoggedIn
                      ? _vm.customer
                      : _vm.isFarmerOrFarmerManagerLoggedIn
                      ? _vm.contractor
                      : "N/A"
                  )
                ),
              ]),
            ],
            1
          ),
          _vm.operators.length
            ? _c(
                VTooltip,
                {
                  attrs: {
                    bottom: "",
                    "nudge-right": "-28",
                    "content-class": "black rounded-0 px-4 pb-2",
                    transition: "scroll-y-transition",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({ staticClass: "d-flex" }, on),
                              [
                                _vm.isSingleDayJob && _vm.operators.length > 1
                                  ? _c(
                                      "Avatar",
                                      {
                                        attrs: {
                                          initials: _vm.operators[0].name,
                                          isCircular: "false",
                                          contentClass: "fw-7 f-10",
                                        },
                                      },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: {
                                              size: "13",
                                              color: "white",
                                            },
                                          },
                                          [_vm._v("mdi-account")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.operators.length) +
                                            " "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm.operators.length === 3
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.operators,
                                        function (operator, index) {
                                          return _c("Avatar", {
                                            key: index,
                                            staticClass: "job-operator",
                                            attrs: {
                                              isCircular: "false",
                                              contentClass: "fw-7 f-10",
                                              initials: operator.name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.operators.slice(0, 2),
                                          function (operator, index) {
                                            return _c("Avatar", {
                                              key: index,
                                              staticClass: "job-operator",
                                              attrs: {
                                                isCircular: "false",
                                                contentClass: "fw-7 f-10",
                                                initials: operator.name,
                                              },
                                            })
                                          }
                                        ),
                                        _vm.operators.length > 2
                                          ? _c(
                                              "Avatar",
                                              {
                                                staticClass: "job-operator",
                                                attrs: {
                                                  isCircular: "false",
                                                  contentClass: "fw-7 f-10",
                                                  initials:
                                                    _vm.operators[
                                                      _vm.operators.length - 1
                                                    ].name,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " +" +
                                                    _vm._s(
                                                      _vm.operators.length - 2
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2114477972
                  ),
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column" },
                    _vm._l(_vm.operators, function (operator, index) {
                      return _c("span", { key: index, staticClass: "py-1" }, [
                        _vm._v(_vm._s(operator.name)),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _c(
            VIcon,
            {
              staticClass: "job-menu",
              attrs: { size: "20" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.emitJobMenu($event)
                },
              },
            },
            [_vm._v(" mdi-dots-vertical ")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }