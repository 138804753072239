var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "field-info-window",
      staticStyle: { "pointer-events": "none !important" },
    },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "fi-title pb-2" }, [
          _vm._v(" " + _vm._s(_vm.fieldInfo.fieldName) + " "),
        ]),
        _c("div", { staticClass: "pb-4 fi-ar-crop" }, [
          _c("div", { staticClass: "ib area-wrapper" }, [
            _vm._v(
              " " + _vm._s(_vm.getAreaFormatted(_vm.fieldInfo.fieldArea)) + " "
            ),
          ]),
          _vm.fieldInfo &&
          _vm.fieldInfo.fieldCrop &&
          _vm.fieldInfo.fieldCrop.name
            ? _c("div", { staticClass: "ib crop-wrapper ml-4" }, [
                _vm._v(
                  " Crop: " +
                    _vm._s(_vm.fieldInfo.fieldCrop.name) +
                    " " +
                    _vm._s(
                      _vm.fieldInfo.fieldCrop.year
                        ? "(" + _vm.fieldInfo.fieldCrop.year + ")"
                        : ""
                    ) +
                    " "
                ),
              ])
            : _c("div", { staticClass: "ib" }),
        ]),
        _vm.fieldInfo.fieldAddress
          ? _c("div", { staticClass: "pb-2 fi-address d-flex align-center" }, [
              _c("img", {
                attrs: { src: require("../../assets/icon-map-marker.jpg") },
              }),
              _c("span", { staticClass: "ib ml-2" }, [
                _vm._v(_vm._s(_vm.fieldInfo.fieldAddress)),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "pb-2 fi-cust d-flex align-center" }, [
          _c("img", {
            attrs: { src: require("../../assets/icon-customers.jpg") },
          }),
          _c("span", { staticClass: "ib ml-2" }, [
            _vm._v(_vm._s(_vm.fieldInfo.fieldCustomer)),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }